
























import { Component, Prop, Vue } from 'vue-property-decorator'
import Context from '@/module/Context'

  @Component({
    components: {}
  })
export default class layoutHeader extends Vue {
    @Prop({ required: true }) context!: Context;

    onGenderTouchMove (e: TouchEvent):void {
      const target = e?.target as HTMLImageElement
      const rect = target.getBoundingClientRect()
      const x = e.targetTouches[0].pageX - rect.left
      if (x < 35) {
        this.context.gender = 'male'
      } else if (x > 105 - 35) {
        this.context.gender = 'female'
      } else {
        this.context.gender = 'both'
      }
    }

    onGenderClick (e: MouseEvent):void {
      if (e.offsetX < 35) {
        this.context.gender = 'male'
      } else if (e.offsetX > 105 - 35) {
        this.context.gender = 'female'
      } else {
        this.context.gender = 'both'
      }
    }
}
