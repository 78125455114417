import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

interface State {
  pwa: {
    ready: boolean
  }
}

export default new Vuex.Store<State>({
  state: {
    pwa: {
      ready: true
    }
  },
  mutations: {
    SET_PWA_READY (state: State, value: boolean) {
      state.pwa.ready = value
    }
  },
  actions: {
    setPWAReady ({ commit }, value: boolean) {
      console.log('Setting readiness', value)
      commit('SET_PWA_READY', value)
    }
  },
  modules: {
  }
})
