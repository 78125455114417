

























































































import { Component, Vue } from 'vue-property-decorator'
import ChevronsButton from '@/components/ChevronsButton.vue'

  @Component({
    components: { ChevronsButton }
  })
export default class LandingView extends Vue {
    toggle1 = false;
    toggle2 = false;
    toggle3 = false;
    toggle4 = false;

    setLocale (locale: 'de' | 'fr' | 'en'): void {
      this.$i18n.locale = locale
    }

    getLocale (): 'de' | 'fr' | 'en' | string {
      return this.$i18n.locale
    }
}
