































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PageModel from '@/models/assets/PageModel'
import ImageModel from '@/models/assets/ImageModel'
import DocumentModel from '@/models/assets/DocumentModel'
import VideoModel from '@/models/assets/VideoModel'
import PhotoGallery from '@/components/PhotoGallery.vue'
import LinkModel from '@/models/assets/LinkModel'
import axios from 'axios'

interface IVimeo {
  Player: {
    new(container: any, options?: any): any
  }
}

declare let Vimeo: IVimeo

  @Component({
    components: { PhotoGallery }
  })
export default class AssetsModal extends Vue {
    @Prop({ required: true }) title!: string;
    @Prop({ required: true }) pageId!: number;

    thumbnails = {}

    vimeoPlayer: {
      destroy: () => void
    } | null = null;

    videoPlay = false;
    showOfflineError = false;
    loading = false;
    loadingThumbnails = true

    error:string|null = null;
    page:PageModel|null = null;

    get images (): ImageModel[] {
      return this.page?.images || []
    }

    get imageArray (): string[] {
      return this.images.map(obj => obj.image)
    }

    get videos (): VideoModel[] {
      return this.page?.videos || []
    }

    get documents (): DocumentModel[] {
      return this.page?.documents || []
    }

    get links (): LinkModel[] {
      return this.page?.links || []
    }

    @Watch('page')
    async loadAllThumbnails (page: PageModel | null) {
      if (!page) return
      this.loadingThumbnails = true
      await Promise.all(page.videos.map(this.getVideoThumbnail))
      this.loadingThumbnails = false
    }

    mounted (): void {
      this.fetchPage(this.pageId)
    }

    documentName (url: string): string {
      return url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'))
    }

    beforeDestroy (): void {
      if (this.vimeoPlayer) this.vimeoPlayer.destroy()
    }

    async onVideoClick (video: VideoModel): Promise<void> {
      this.videoPlay = true
      await this.$nextTick()
      const container = this.$refs['video-player'] as HTMLDivElement | undefined
      if (!container) return
      const rect = container.getBoundingClientRect()
      if (this.vimeoPlayer) this.vimeoPlayer.destroy()
      const options = {
        url: video.url,
        width: rect.width,
        height: rect.height
      }
      this.vimeoPlayer = new Vimeo.Player(container, options)
    }

    getURL (url): string {
      // TODO: Remove the second replace after DNS entry and SSL for docs.sw-rtp.swiss-aquatics.ch is available
      return url.replace('https://', 'https://docs.').replace('docs.sw-rtp.swiss-aquatics.ch', 'docs.rtp.smf.ai')
    }

    async getVideoThumbnail (video: VideoModel) {
      const videocode = video.url.substr(video.url.lastIndexOf('/') + 1)
      const response = await axios.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videocode}`)
      const url = response.data.thumbnail_url_with_play_button

      // This ensures that all thumbnails have the same resolution
      const parsedUrl = new URL(url)
      let thumbnailSource = parsedUrl.searchParams.get('src0')
      if (thumbnailSource && !thumbnailSource.endsWith('_640')) {
        const split = thumbnailSource.split('_')
        split[split.length - 1] = '640'
        thumbnailSource = split.join('_')
        parsedUrl.searchParams.set('src0', thumbnailSource)
      }

      this.thumbnails[video.url] = parsedUrl.toString()
    }

    async fetchPage (pageId: number): Promise<void> {
      this.loading = true
      this.error = null

      return axios.get(process.env.VUE_APP_API_ROOT + '/pages/' + pageId + '/')
        .then(response => {
          this.page = response.data
        }).catch(e => {
          if (typeof e.response === 'object') {
            if (e.response.status === 404) {
              this.error = this.$t('assets.not.found').toString()
            } else {
              this.error = e
            }
          } else {
            this.error = e
            this.showOfflineError = true
          }
          this.page = null
        }).finally(() => {
          this.loading = false
        })
    }
}
