



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Ftems } from '@/module/const'
import ChevronsButton from '@/components/ChevronsButton.vue'
import Context from '@/module/Context'

  @Component({
    components: { ChevronsButton }
  })
export default class HomeView extends Vue {
    ftems = Ftems;

    @Prop({ required: true }) context!: Context;

    mounted ():void {
      if (!this.context.isAuthenticated()) {
        this.$router.push({ name: 'LandingView' })
      }
    }
}
