




























import { Component, Vue } from 'vue-property-decorator'
import { contents, Ftems } from '@/module/const'
import PhaseCard from '@/components/PhaseCard.vue'
import ChevronsButton from '@/components/ChevronsButton.vue'
import FtemModel from '@/models/FtemModel'
import PhaseModel from '@/models/PhaseModel'

  @Component({
    components: { ChevronsButton, PhaseCard }
  })
export default class FtemView extends Vue {
  get ftem (): FtemModel | null {
    return Ftems.find(ftem => ftem.code === this.$route.params.ftem) || null
  }

  getPhase (phaseCode: string): PhaseModel | null {
    const lang = this.$i18n.locale
    return contents[lang].find(content => content.code === phaseCode) || null
  }
}
