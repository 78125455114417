import PhaseModel from '@/models/PhaseModel'
import FtemModel from '@/models/FtemModel'

export const Ftems: FtemModel[] = [
  {
    code: 'foundation',
    label: 'FOUNDATION',
    phaseCodes: ['F1a > F1b', 'F2', 'F3']
  },
  {
    code: 'talent',
    label: 'TALENT',
    phaseCodes: ['T1', 'T2', 'T3', 'T4']
  },
  {
    code: 'elite',
    label: 'ELITE',
    phaseCodes: ['E1', 'E2']
  },
  {
    code: 'mastery',
    label: 'MASTERY',
    phaseCodes: ['M']
  }
]

export const contents: {
  [name: string]: PhaseModel[]
} = {
  de: require('@/i18n/de/content.yml'),
  fr: require('@/i18n/fr/content.yml'),
  en: require('@/i18n/en/content.yml')
}

export const trainingCodes: string[] = [
  'reg',
  'aec1',
  'aec2',
  'aep',
  'anc',
  'anp',
  's'
]

export const trainingCategoryCodes: string[] = [
  'description',
  'loadingduration',
  'rpe',
  'vinofactpb',
  'lactate',
  'heartrate',
  'vo2max',
  'pause',
  'recovery',
  'sd',
  'md',
  'ld',
  'methods',
  'particularities'
]
