






















import { Component, Prop, Vue } from 'vue-property-decorator'
import ChevronsButton from '@/components/ChevronsButton.vue'
import FtemModel from '@/models/FtemModel'
import PhaseModel from '@/models/PhaseModel'

  @Component({
    components: { ChevronsButton }
  })
export default class PhaseCard extends Vue {
    @Prop({ required: true }) ftem!: FtemModel;
    @Prop({ required: true }) phase!: PhaseModel | null;
}
