























import { Component, Prop, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

  @Component({
    components: {}
  })
export default class ChevronsButton extends Vue {
    @Prop({ default: null }) to!: RawLocation | null
    @Prop({ default: null }) toRight!: RawLocation | null | boolean
    @Prop({ default: null }) toLeft!: RawLocation | null | boolean
    @Prop({ default: false }) big!: boolean
    @Prop({ default: false }) light!: boolean
}
