
























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { trainingCategoryCodes, trainingCodes } from '@/module/const'
import PhaseCard from '@/components/PhaseCard.vue'
import ChevronsButton from '@/components/ChevronsButton.vue'
import Context from '@/module/Context'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { SwiperOptions } from 'swiper'
import { Location } from 'vue-router'

  @Component({
    components: {
      ChevronsButton,
      PhaseCard,
      Swiper,
      SwiperSlide
    }
  })
export default class TrainingView extends Vue {
    readonly $refs!: { [key: string]: (Vue | Element | Vue[] | Element[]) & { swiperInstance: { slideTo: (index: number, speed?: number) => void } } }

    @Prop({ required: true }) context!: Context;

    trainingCodes = trainingCodes;
    trainingCategoryCodes = trainingCategoryCodes;
    swiperCategoriesOptions: SwiperOptions = {
      slidesPerView: 'auto',
      freeMode: true,
      centerInsufficientSlides: true
    }

    currentTrainingCode: string | null = null
    toggles: string[] = []

    get training ():string {
      return this.$route.params.code
    }

    mounted (): void {
      if (!this.context.isAuthenticated()) {
        this.$router.push({ name: 'LandingView' })
        return
      }
      this.updatePhaseSwiper()
      if (!this.currentTrainingCode) {
        this.currentTrainingCode = this.$route.params.code
      }
    }

    updated (): void {
      this.updatePhaseSwiper()
    }

    updatePhaseSwiper (): void {
      const index = this.trainingCodes.findIndex(p => p === this.$route.params.code)
      if (index !== -1) {
        this.$refs['trainings-swiper'].swiperInstance.slideTo(index, 0)
        this.$refs['categories-swiper'].swiperInstance.slideTo(index, 0)
        if (this.currentTrainingCode) {
          this.currentTrainingCode = this.trainingCodes[index] || null
        }
      }
    }

    onTrainingSlide (swiper: { activeIndex: number }): void {
      const trainingCode = this.trainingCodes[swiper.activeIndex]
      const route = this.getTrainingRoute(trainingCode)
      if (route?.params && route.params.code !== this.$route.params.code) {
        this.$router.push(route)
      }
    }

    onCategoryClick (index: number): void {
      this.currentTrainingCode = this.trainingCodes[index] || null
      const trainingCode = this.trainingCodes[index]
      const route = this.getTrainingRoute(trainingCode)
      if (route?.params && route.params.code !== this.$route.params.code) {
        this.$router.push(route)
      }
    }

    isToggled (trainingCategoryCode: string): boolean {
      return this.toggles.indexOf(trainingCategoryCode) !== -1
    }

    toggle (trainingCategoryCode: string): void {
      const index = this.toggles.indexOf(trainingCategoryCode)
      if (index !== -1) {
        this.toggles.splice(index, 1)
      } else {
        this.toggles.push(trainingCategoryCode)
      }
    }

    getPreviousTrainingRoute (index: number): Location | boolean {
      const nextTraining = this.trainingCodes[index - 1]
      if (!nextTraining) return false
      return this.getTrainingRoute(nextTraining) || false
    }

    getNextTrainingRoute (index: number): Location | boolean {
      const nextTraining = this.trainingCodes[index + 1]
      if (!nextTraining) return false
      return this.getTrainingRoute(nextTraining) || false
    }

    getTrainingRoute (trainingCode: string): Location | undefined {
      if (trainingCode) {
        const params = { code: trainingCode }
        return { name: 'TrainingView', params: params }
      }
    }

    getContent (trainingCategoryCode: string): string {
      return this.$t('training.categories.' + trainingCategoryCode + '.' + this.training + '').toString()
    }
}
