










import { Component, Vue, Watch } from 'vue-property-decorator'
import LayoutHeader from '@/components/LayoutHeader.vue'
import Context from '@/module/Context'
import Loader from '@/components/Loader.vue'

  @Component({
    components: { Loader, LayoutHeader }
  })
export default class App extends Vue {
    context: Context = new Context()

    get isLoading () {
      return !this.$store.state.pwa.ready
    }

    mounted () {
      const locale = localStorage.getItem('locale')
      if (locale) {
        this.$i18n.locale = locale
      }
    }

    @Watch('$i18n.locale')
    handleLocale (locale: string) {
      if (locale) {
        localStorage.setItem('locale', locale)
      }
    }
}
