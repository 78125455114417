
























import axios from 'axios'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Context from '@/module/Context'

  interface LoginResponse {
    data: {
      // eslint-disable-next-line camelcase
      account_id: string;
    };
    success: boolean;
  }

  @Component({
    components: {}
  })
export default class LoginView extends Vue {
    @Prop({ required: true }) context!: Context;

    form: {
      email: string;
      password: string;
    } = {
      email: '',
      password: ''
    }

    loading = false;
    error: string | null = null;

    mounted ():void {
      if (this.context.isAuthenticated()) {
        this.$router.push({ name: 'HomeView' })
      }
    }

    async login ():Promise<void> {
      this.loading = true
      try {
        this.error = null
        const response = await axios.post<LoginResponse>(process.env.VUE_APP_API_ROOT + '/user/', this.form)
        if (response.data.success && response.data.data.account_id) {
          localStorage.setItem('account_id', response.data.data.account_id)
          await this.$router.push({ name: 'HomeView' })
        }
      } catch (e) {
        if (typeof e.response === 'object') {
          if (e.response.status === 400) {
            this.error = this.$t('login.errors.emailinvalid').toString()
          } else if (e.response.status === 422) {
            this.error = this.$t('login.errors.credentialsinvalid').toString()
          } else {
            this.error = e
          }
        } else {
          this.error = e
        }
      }
      this.loading = false
    }
}
