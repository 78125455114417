

























import SwiperClass, { Navigation, Pagination, SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import { Component, Vue, Prop } from 'vue-property-decorator'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

SwiperClass.use([Navigation, Pagination])
  @Component({
    components: {
      Swiper,
      SwiperSlide,
      CoolLightBox
    }
  })

export default class PhotoGallery extends Vue {
  @Prop({ required: true }) items!: [];

  directives = {
    swiper: directive
  };

  index: number | null = null;
  swiperOptions: SwiperOptions = {
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination'
    },
    observer: true,
    observeParents: true
  };

  get swiper (): any {
    return this.$refs.imageSwiper
  }

  updateIndex (value: number): void {
    this.index = value
    this.swiper.$swiper.activeIndex = this.index
  }
}
