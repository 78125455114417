


































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { contents, Ftems } from '@/module/const'
import PhaseCard from '@/components/PhaseCard.vue'
import ChevronsButton from '@/components/ChevronsButton.vue'
import CategoryModel from '@/models/CategoryModel'
import CellModel from '@/models/CellModel'
import PhaseModel from '@/models/PhaseModel'
import FtemModel from '@/models/FtemModel'
import Context from '@/module/Context'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { SwiperOptions } from 'swiper'
import { Location } from 'vue-router'
import SubcategoryModel from '@/models/SubcategoryModel'
import AssetsModal from '@/components/AssetsModal.vue'
import AssetsModalBar from '@/components/AssetsModalBar.vue'

  @Component({
    components: {
      AssetsModal,
      AssetsModalBar,
      ChevronsButton,
      PhaseCard,
      Swiper,
      SwiperSlide
    }
  })
export default class PhaseView extends Vue {
    readonly $refs!: { [key: string]: (Vue | Element | Vue[] | Element[]) & { swiperInstance: { slideTo: (index: number, speed?: number) => void } } }

    @Prop({ required: true }) context!: Context;
    swiperCategoriesOptions: SwiperOptions = {
      slidesPerView: 'auto',
      freeMode: true,
      centerInsufficientSlides: true,
      on: {
        // The events below are there to emulate the native mobile touch: on tap when moving should stop the transitions
        tap: (swiper, event) => {
          if (this.isCategorySwiperMoving) {
            swiper.translateTo(swiper.getTranslate(), 0)
          } else {
            if (!event.target) {
              return
            }
            const target: any = event.target
            const categoryId = target.getAttribute('data-category')
            if (!categoryId) {
              return
            }
            const index = parseInt(categoryId)
            this.currentCategory = this.categories[index] || null
            this.currentCategoryId = index
            this.$refs['cells-swiper'].swiperInstance.slideTo(index)
          }
        },
        touchStart: (swiper) => {
          if (this.isCategorySwiperMoving) {
            swiper.translateTo(swiper.getTranslate(), 0)
          }
        },
        transitionStart: (swiper) => {
          this.isCategorySwiperMoving = true
        },
        transitionEnd: (swiper) => {
          this.isCategorySwiperMoving = false
        }
      }
    }

    isCategorySwiperMoving = false

    swiperCellsOptions: SwiperOptions = {}
    currentCategory: CategoryModel | null = null
    currentCategoryId:number|null=0

    pageId: number|null = null

    toggles: string[] = []

    get phaseCodes (): string[] {
      return Ftems.reduce((arr, ftem) => [...arr, ...ftem.phaseCodes], [] as string[])
    }

    get ftem (): FtemModel | null {
      return Ftems.find(ftem => ftem.code === this.$route.params.ftem) || null
    }

    get phase (): PhaseModel | null {
      const lang = this.$i18n.locale
      return contents[lang].find(content => content.code === this.$route.params.phase) || null
    }

    get categories (): CategoryModel[] {
      return this.phase?.children || []
    }

    mounted (): void {
      if (!this.context.isAuthenticated()) {
        this.$router.push({ name: 'LandingView' })
        return
      }
      this.updatePhaseSwiper()
      if (!this.currentCategory) {
        this.currentCategory = this.categories.find(c => c) || null
      }
    }

    updated (): void {
      this.updatePhaseSwiper()
    }

    getFtemColor (indexModifier: number): string | null {
      const phase = this.phase ?? { code: '' }
      const phaseIndex = this.phaseCodes.indexOf(phase.code) + indexModifier
      switch (phaseIndex) {
        case 0:
        case 1:
        case 2:
          return 'bg--foundation'
        case 3:
        case 4:
        case 5:
        case 6:
          return 'bg--talent'
        case 7:
        case 8:
          return 'bg--elite'
        case 9:
          return 'bg--mastery'
        default:
          return 'invisible'
      }
    }

    updatePhaseSwiper (): void {
      const index = this.phaseCodes.findIndex(p => p === this.$route.params.phase)
      if (index !== -1) {
        this.$refs['phases-swiper'].swiperInstance.slideTo(index, 0)
        if (this.currentCategory) {
          const categoryIndex = this.categories.findIndex(c => c.label === this.currentCategory?.label)
          if (categoryIndex !== -1) {
            this.currentCategory = this.categories[categoryIndex] || null
            this.currentCategoryId = categoryIndex
          }
        }
      }
    }

    onPhaseSlide (swiper: { activeIndex: number }): void {
      const phaseCode = this.phaseCodes[swiper.activeIndex]
      const route = this.getPhaseRoute(phaseCode)
      if (route?.params && route.params.phase !== this.$route.params.phase) {
        this.$router.push(route)
      }
    }

    onCategoryCellsSlide (swiper: { activeIndex: number }): void {
      this.currentCategory = this.categories[swiper.activeIndex] || null
      this.$refs['categories-swiper'].swiperInstance.slideTo(swiper.activeIndex)
      window.scrollTo({ top: Math.min(109, window.scrollY), behavior: 'smooth' })
    }

    showModal (pageId:number):void{
      this.pageId = pageId
    }

    hideModal ():void{
      this.pageId = null
    }

    getPreviousPhaseRoute (index: number): Location | boolean {
      const nextPhase = this.phaseCodes[index - 1]
      if (!nextPhase) return false
      return this.getPhaseRoute(nextPhase) || false
    }

    getNextPhaseRoute (index: number): Location | boolean {
      const nextPhase = this.phaseCodes[index + 1]
      if (!nextPhase) return false
      return this.getPhaseRoute(nextPhase) || false
    }

    getPhaseRoute (phaseCode: string): Location | undefined {
      if (phaseCode) {
        const ftem = Ftems.find(f => f.phaseCodes.indexOf(phaseCode) !== -1)
        if (ftem) {
          const params = { ftem: ftem.code, phase: phaseCode }
          return { name: 'PhaseView', params: params }
        }
      }
    }

    getSubcategories (category: CategoryModel): SubcategoryModel[] {
      return category?.subcategories || []
    }

    getCells (subcategory: SubcategoryModel): CellModel[] {
      const genders = ['both']
      if (this.context.gender === 'both') {
        genders.push('male', 'female')
      } else {
        genders.push(this.context.gender)
      }
      return (subcategory?.cells || []).filter(c => genders.indexOf(c.gender) !== -1)
    }

    addIcons (text: string): string {
      return text.replace('[icon-check]', '<span class="icon check"></span>')
        .replace('[icon-warn]', '<span class="icon warn"></span>\n')
        .replace('[icon-plus]', '<span class="icon plus"></span>\n')
    }

    isToggled (subcategory: string): boolean {
      return this.toggles.indexOf(subcategory) !== -1
    }

    toggle (subcategory: string): void {
      const index = this.toggles.indexOf(subcategory)
      if (index !== -1) {
        this.toggles.splice(index, 1)
      } else {
        this.toggles.push(subcategory)
      }
    }
}
