import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'

import 'swiper/swiper-bundle.css'

Vue.config.productionTip = false
Vue.use(VueI18n)

const messages = {
  de: require('@/i18n/de/message.yml'),
  fr: require('@/i18n/fr/message.yml'),
  en: require('@/i18n/en/message.yml')
}

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
