import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LandingView from '@/views/LandingView.vue'
import LoginView from '@/views/LoginView.vue'
import HomeView from '@/views/HomeView.vue'
import FtemView from '@/views/FtemView.vue'
import PhaseView from '@/views/PhaseView.vue'
import TrainingView from '@/views/TrainingView.vue'
import TrainingsView from '@/views/TrainingsView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/landing',
    name: 'LandingView',
    component: LandingView
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/trainings',
    name: 'TrainingsView',
    component: TrainingsView,
    meta: {
      showHeaderBurger: true
    }
  },
  {
    path: '/trainings/:code',
    name: 'TrainingView',
    component: TrainingView,
    meta: {
      showHeaderBurger: true
    }
  },
  {
    path: '/:ftem',
    name: 'FtemView',
    component: FtemView,
    meta: {
      showHeaderBurger: true
    }
  },
  {
    path: '/:ftem/:phase',
    name: 'PhaseView',
    component: PhaseView,
    meta: {
      showHeaderBurger: true,
      showHeaderGender: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
